<template>
  <div id="app">
    <header>
      <div class="container-fluid">
        <div class="row">
          <div class="col-6 text-left"><img height="70" src="@/assets/Sokkelund-logo1.webp"></div>
          <div class="col-6 text-right">
            <button v-if="silent" class="coffee mr-3 btn-danger" @click="silent = !silent">Lydløs</button>
            <button v-if="!silent" class="coffee mr-3 " @click="silent = !silent">Lyd til</button>

            <button v-if="coffeeBreak" class="coffee" @click="coffeeBreak = !coffeeBreak">Stop Musik</button>
            <button v-if="!coffeeBreak" class="coffee" @click="coffeeBreak = !coffeeBreak">Kaffepause</button></div>
        </div>
      </div>
    </header>
    <div v-if="coffeeBreak" style="background-color: #af1316">

      <img style="height: 100vh" src="@/assets/pause.png">
    </div>

    <div v-if="coffeeBreak" style="display: none">
      <iframe  width="100%" height="0" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/109154056&color=%23ff5500&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/thomasfinn" title="Thomas Finn" target="_blank" style="color: #cccccc; text-decoration: none;">Thomas Finn</a> · <a href="https://soundcloud.com/thomasfinn/kalder-pa-dig" title="Kalder På Dig" target="_blank" style="color: #cccccc; text-decoration: none;">Kalder På Dig</a></div>
    </div>

    <router-view v-if="!coffeeBreak" :silent="silent"/>
  </div>

</template>
<script>
// @ is an alias to /src
export default {
  data() {

    return {
      coffeeBreak: false,
      silent: false
    }
  },

};
</script>
<style lang="scss">
body,html{
  padding: 0;
  margin: 0;
}

.coffee{
  border: 0 !important;
  background-color: #484848;
  height: 30px;
  margin-top: 10px;
  width: 100px;
  font-size: 12px !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 0;
  margin: 0;
  height: 100vh;
}

header{
  overflow: hidden;
  background-color: #333;
  height: 50px;
}
</style>
