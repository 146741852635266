<template>
  <div class="home">

    <div class="banner" v-if="banner" @click="banner = false" :style="{'backgroundColor': banner.color}">
      {{ banner.label }}
    </div>

    <div class="row">
      <div v-for="column in buttons" :key="column.id" class="col pl-0 pr-0 d-flex flex-column">
        <div class="column-name">{{column.label}}</div>
        <template v-for="group in column.button_groups">
          <div v-for="button in group.buttons" :key="button.id" class="box" :style="{'backgroundColor': group.color}">
            <button @click="toggleButton(button, group.color)" :class="'state-' + button.state">{{ button.label }}</button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Home",
  props: {

    silent: Boolean
  },
  mounted() {
    this.$socket.emit('get buttons')
  },
  methods: {

    sync() {
      this.$socket.emit('get buttons')
    },
    toggleButton(button, color) {


      if (button.state == 0) {
        button.state = 1;
        if(button.first_sound !== null){
          this.$socket.emit('sound', button.first_sound);
          this.$socket.emit('banner', button.label, color);
        }

      } else if (button.state == 1) {
        button.state = 2;
        if(button.first_sound !== null){
          this.$socket.emit('sound', button.second_sound);
          this.$socket.emit('banner', button.label, color);
        }
      } else if (button.state == 2) {
        button.state = 0;
      }

      this.$socket.emit('button click', this.buttons)
    }
  },
  data() {

    return {
      banner: false,
      buttons: {}
    }
  },
  sockets: {
    connect: function () {
      this.$socket.emit('get buttons');
    },

    'sound': function (data) {

      if(!this.silent){
        var audio = new Audio(data)
        audio.play()
      }

    },

    'banner': function (banner, color) {
      this.banner = banner;

      let self = this;
      setTimeout(function(){ self.banner = false }, 30000);
    },

    'state': function (data) {
      this.buttons = data;


    },
  },
};
</script>

<style type="text/scss">

.banner{
  border-top: 1px solid #fff;
  width: 100vw;
  color: #fff;
  font-size: 7vw !important;
  position: absolute;
  bottom: 0;
  z-index: 10;
  padding: 20px 0;
  text-transform: uppercase;
  background-color: #000;

}
.row,
.home {
  overflow: hidden;
  height: calc(100vh - 50px);
}

.col:last-child {

button {
  border-right: 0 !important;
}

}

.column-name{
  padding: 10px 0;
  font-weight: 500;
  font-size: 1.2vw !important;
  text-transform: uppercase;
  background-color: #626262;
  color: #fff;
  border-right: 1px solid #ffffff57;

  &:last-child{
    border-right: 0;
  }
}
button {
  border: 0;
  width: 100%;
  font-size: 18px;
  height: 100%;
  text-transform: uppercase !important;
  font-size: 24px !important;
  font-weight: 500;
  color: #fff;
  font-size: 1.2vw !important;
  text-transform: uppercase;
  border-bottom: 1px solid #ffffff57;
  background-color: transparent;
  border-right: 1px solid #ffffff57;

  &:focus {
    outline: none !important;
  }

}

.box {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.state-1 {
  background-color: #fdcb6e;
}

.state-2 {
  background-color: #d63031;
}

textarea, select, input, button { outline: none; }
</style>